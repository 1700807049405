import React, { Component } from 'react';
import GoogleMapReact from 'google-map-react';

const AnyReactComponent = ({ text }) => <div>{text}</div>;

class SimpleMap extends Component {
  static defaultProps = {
    center: {
      lat: 47.1239599,
      lng: 17.3677463
    },
    zoom: 15
  };

  render() {
    return (
      <div className='map' style={{ height: '400px', width: '100%' }}>
        <GoogleMapReact
          bootstrapURLKeys={{ key:'AIzaSyCw8Z9nvQbM9s8ejZ0cnskqieEFlDB9yOo'  }}
          defaultCenter={this.props.center}
          defaultZoom={this.props.zoom}
        >
          <AnyReactComponent
            lat={47.1239599}
            lng={17.3677463}
            text="Tokolics Attila"
          />
        </GoogleMapReact>
      </div>
    );
  }
}

export default SimpleMap;