import React from "react";
import { withRouter } from "react-router-dom";
import emailjs from 'emailjs-com';
import Reveal from 'react-reveal/Reveal';

import SimpleMap from '../components/Map';
import Footer from '../components/Footer';

import { Efect, Efect1, Efect2  } from "../styles/effect.styles";
  

const Contact = ({ history }) => {

    function sendEmail(e) {
    const success = document.getElementById("success");
    const button = document.getElementById("buttonsent");
    const failed = document.getElementById("failed");
    e.preventDefault();

    emailjs.sendForm('fazeone', 'template_q00z7hc', e.target, 'user_55JmfYo6OSGP8qXL2LIld')
      .then((result) => {
          console.log(result.text);
          success.classList.add('show');
          button.classList.add('show');
          failed.classList.remove('show');
      }, (error) => {
          console.log(error.text);
          failed.classList.add('show');
      });
  }  

  return (
    <div>
      <Efect/>
      <Efect1/>
      <Efect2/>

      <div className="jumbotron head"/>

      <Reveal effect="fadeInUp">
       <section className='container'>
          <div className='row'>
            <div className='col-12'>
              <h1>Elérhetőségeink</h1>
            </div>
          </div>
        </section>
      </Reveal>

      <Reveal effect="fadeInUp">
       <section className='container pt-0'>
          <div className='row'>
            <div className='col-12'>
              <SimpleMap/>
            </div>
            <div className='col-md-6'>
            <div className="text-side">
              <h3 className="heading">Keressen minket, állunk rendelkezésére!</h3>
              <p>Kérdése van, vagy ajánlatot szeretne kérni?</p>
              <div className='address'>
                <div className='heading'>Műhelyünk</div>
                <div className='list'>
                  <i className="fa fa-map-marker"></i>
                  8481 Somlóvásárhely, Vásár u. 12.
                </div>
                <div className='list'>
                  <i className="fa fa-envelope-o"></i>
                  <a href='mailto:contact_info@homekins.com' target='_blank' rel='noopener noreferrer'>
                    info@tokolics.hu
                  </a>
                </div>
                <div className='list'>
                  <i className="fa fa-phone"></i>
                  +36 30 375 2475
                </div>
                </div>
              </div>
            </div>

            <div className='col-md-6'>
            <div className="form-side">
              <form className="formcontact" onSubmit={sendEmail} id="myForm">
                <label>Név</label>
                <input type="text" name="user_name" required />
                <label>Email</label>
                <input type="email" name="user_email" required />
                <label>Üzenet</label>
                <textarea name="message" required />
                <div id='success' className='hide'>Üzenete sikeresen elküldve, hamarosan válaszolunk...</div>
                <div id='failed' className='hide'>Küldés sikertelen...</div>
                <button type="submit" id='buttonsent'>
                  <span className="shine"></span>
                  <span>Küldés</span>
                </button>
              </form>
            </div>
          </div>


          </div>
       </section>
      </Reveal>

      

      <Footer />

    </div>
    
  );
};

export default withRouter(Contact);
