import React from 'react';

export default () => (
	<section className='container-fluid'>
		<div className='row m-2-hor'>
			<div className='col-md-6 pt-5'>
				<div className='col-home'>
					<div className='thumb'>
						<img src='./img/home01.jpg' className='img-fluid' alt='#' />
					</div>
				</div>
			</div>

			<div className='col-md-6'>
				<div className='dflex-center'>
					<div className='col-home mt-md-0 mt-5'>
						<div className='heading'>Tulajdonképpen mindent gyártunk, ami fából elkészíthető…</div>
						<div className='content'>
							Több mint 30 éves szakmai gyakorlattal állunk megrendelőink rendelkezésére, kiemelt hangsúlyt fektetve a legjobb minőségre, hagyományos technológiával, egyedi igények szerint. Műhelyünk felszereltsége folyamatosan igazodik a minden igényt kielégítő, minőségi munkák kivitelezéséhez.<br />Kérjük, tekintse meg referenciáinkat, amelyeket magánszemélyek, vállalkozások, építőipari kivitelezők részére készített munkáinkból válogattunk, a teljesség igénye nélkül. Amennyiben bemutatkozásunk
							felkeltette érdeklődését, forduljon hozzánk bizalommal! Kérjen árajánlatot elérhetőségeink bármelyikén!
						</div>
						<ul className='list-home'>
							<li>Külső és belső nyílászárók</li>
							<li>Bútorok fából és bútorlapból</li>
							<li>Lépcsők, hajlított fakorlátok</li>
							<li>Galériák</li>
              <li>Pergolák</li>
              <li>Szaunák</li>
              <li>Látványterv készítés</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
	</section>
);
